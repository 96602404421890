.memberCard{
  margin-bottom: 40px;
}
.sort-by-dropdown {
  font-family: Lato 700;
  padding-right: 1.5rem;
  padding-left: 1rem;
  margin-left: 10px;
}
.divider-line-above-view {
  padding-bottom: 20px;
  width: 100%;
  margin: auto;
}
.dropdown-item:active {
  background-color: #6FBE47;
}
#dropdownMenuButton {
  color: gray;
  font-size: 17px;
  // border-style: none;
  display: inline;
}
.arrow {
  color: transparent;
  display: inline;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.arrow:hover {
  color: transparent;
}
.dropdown-menu.show{
  z-index: 999999;
}
.People__Search .dropdown .dropdown-menu{
  max-height: 400px;
  overflow-y: auto;
}