$bgcolor: lightblue;
$textcolor: gray;
$fontsize: 16px;
$paddind_0:0px;
$inlinebox:flex;
.table {
    border-collapse: separate;
    border-spacing: 0 3px;
}

ul.search_boxnew,
ul.calendarnew {
    margin: 35px 0 30px 0;
    padding: $paddind_0;
    display: $inlinebox;
    list-style: none;
    border: 1px solid #ccc;
}

ul.search_boxnew>li.search-box {
    flex: 0 0 60%;
    height: 50px;
}

ul.search_boxnew>li.dropdown {
    flex: 0 0 20%;
}

ul.search_boxnew>li {
    border-right: 1px solid lightgray;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.search_boxnew>li a {
    color: $textcolor;
    font-size: $fontsize;
}

ul.search_boxnew>li:last-child {
    border: 0;
}

ul.search_boxnew>li.search-box .form-control {
    border: 0px !important;
    height: 50px;
    padding: 0px 25px;
}

ul.calendarnew {
    height: 65px;
    padding-left: 25px;
    padding-right: 25px;
}

.date-text {
    height: 65px;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $textcolor
}

.calendarnew>li {
    display: flex;
}

.calendarnew>li .input-group.date .form-control {
    height: 62px;
    border: 0;
    color: $textcolor
}

.calendarnew>li .input-group.date .form-control:focus {
    box-shadow: none;
}

table.table>thead>tr {
    background-color: #F2F2F2;
}

table.table>thead>tr>th,
table.table>tbody>tr>td {
    vertical-align: middle;
    font-size: 14px;
}

table.table>tbody>tr>td {
    padding: 22px 10px;
}

table.table>thead>tr>th:first-child {
    padding-left: 25px;
    line-height: 23px;
    text-align: center;
}

table.table>thead>tr>th:first-child {
    border-left: 1px solid #dee2e6;
}

table.table>thead>tr>th:last-child {
    border-right: 1px solid #dee2e6;
}

table.table>thead>tr>th:first-child>span {
    font-size: 11px;
}

.table th,
.table td {
    border-bottom: 1px solid #dee2e6;
}

table.table>tbody>tr>td:first-child {
    padding-left: 25px;
    border-left: 1px solid #dee2e6;
}

table.table>tbody>tr>td:last-child {
    border-right: 1px solid #dee2e6;
}

table.table>tbody>tr>td {
    vertical-align: middle;
}

table.table>tbody>tr>td:nth-child(2) {
    font-weight: 600;
}

hr.line {
    margin-top: 15px;
    padding-bottom: 20px;
}

.search_boxnew .btn {
    padding-right: 25px;
}

table.table>thead>tr>th {
    height: 62px;
}


/*check box */

.container1 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    right: 0;
    margin: auto;
}

.container1:hover input~.checkmark {
    background-color: #ccc;
}

.container1 input:checked~.checkmark {
    background-color: #6FBE47;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container1 input:checked~.checkmark:after {
    display: block;
}

.container1 .checkmark:after {
    left: 4px;
    top: -1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

table.table>tbody>tr>td:first-child {
    text-align: center;
}


/* Timesheets Period model style*/

.modelBox {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 25px;
}

ul.profildetail {
    list-style: none;
    display: flex;
    align-items: center;
}

ul.profildetail li.username {
    padding-left: 35px;
}

ul.profildetail li.username h3 {
    color: 6FBE47;
    font-weight: 600;
    margin-bottom: 0;
}

.userprofil {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #F2F2F2;
    border: 1px solid #ccc;
}

.username h3 {
    font-size: 45px;
}

ul.profildetail li.username h4 {
    font-weight: 600;
    font-size: 25px;
}

ul.profildetail li.username h5 {
    font-weight: 300;
    opacity: 0.5;
}

.greenBtn {
    background-color: #6FBE47;
    color: #fff !important;
    padding: 14px 40px;
    font-weight: 300;
    font-size: 17px;
    display: block;
}

ul.profildetail li:last-child {
    justify-content: right;
    display: flex;
    flex: 0 0 55%;
}

.greensmalltext {
    color: #6FBE47 !important;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}


/*Clients for Approval */

.from-for .form-label,
.add_contact .form-label {
    padding-left: 20px;
    padding-right: 20px;
}

.from-for .form-control,
.add_contact .form-control {
    height: 65px;
    border-radius: 0;
    padding: 8px 20px;
}

ul.buttonBlock {
    margin: 15px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

ul.buttonBlock>li {
    padding-right: 20px;
}

ul.buttonBlock .greenBtn {
    width: 150px;
    text-align: center;
}

.width-80 {
    width: 140px;
    text-align: center;
}

.pyroll {
    justify-content: right;
}

.pyroll .form-control {
    height: 55px;
    border-radius: 0;
}

ul.pyroll {
    list-style: none;
    display: flex;
    align-items: center;
}


/* Report issues with timesheets*/

.status_block {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    color: #959595;
}

.status_block>li:nth-child(1),
.status_block>li:nth-child(3),
.status_block>li:nth-child(5) {
    text-align: right;
    padding-right: 25px;
    ;
}

.status_block>li,
.status_block>li:last-child {
    flex: 0 0 50% !important;
}

.status_block>li:last-child {
    justify-content: left !important;
}

.status_block>li>span {
    display: block;
}

.boderbox {
    border: 1px solid #F2F2F2;
    padding: 35px;
}

.h20 {
    height: 240px !important;
}

.report_issues ul.profildetail li:last-child {
    flex: 0 0 62%;
}


/*18-11-2019*/

ul.search_boxnew.single-search>li.search-box {
    flex: 0 0 100%;
}

ul.checkoptionBox {
    list-style: none;
    display: flex;
    margin: 49px 0 15px 25px;
    padding: 0;
    align-items: center;
    justify-content: center;
}

ul.checkoptionBox>li>label.container1 {
    font-size: 18px;
    color: #959595;
    font-weight: 300;
    padding-left: 27px;
}

ul.checkoptionBox>li>label.container1 .checkmark {
    right: auto;
    top: 7px;
}

ul.checkoptionBox>li {
    flex: 1;
}

.editIcon {
    list-style: none;
    display: flex;
    justify-content: right;
}

.detail-info {
    list-style: none;
    margin: 15px 0 0 0;
    padding: 0;
}

.detail-info>li {
    padding-bottom: 8px;
}

.nav.nav-bar.nav-tabs {
    justify-content: right;
    margin-bottom: 25px;
    border-bottom: 0;
}

.nav.nav-bar.nav-tabs a.nav-link.active {
    border: 0 !important;
    border-bottom: 4px solid #6FBE47 !important;
}

.nav.nav-bar.nav-tabs {
    margin-bottom: 0px;
}

.spadress {
    display: flex;
    flex: 0 0 40%;
    flex-wrap: wrap;
    margin-left: 151px;
    text-align: left;
}

.tab-content ul.profildetail li:last-child {
    flex-wrap: wrap;
}

.spadress b {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
}

.tab-content ul.profildetail li:last-child {
    position: relative;
}

.tab-content ul.profildetail li:last-child::before {
    border-right: 1px solid #f4f4f4;
    content: "";
    position: absolute;
    left: 35%;
    height: 100%;
}

ul.nav.nav-bar.nav-tabs .nav-link {
    padding-left: 45px;
    padding-right: 45px;
    font-size: 18px;
    color: #000;
}

.contract_details_block ul.profildetail li:last-child {
    position: relative;
    flex-wrap: wrap;
    flex: 0 0 30%;
}

.contract_details_block ul.profildetail li:last-child .spadress {
    margin-left: 50px;
}

.report_issues.contract_details_block .profildetail.boderbox p {
    color: #797979;
}

.notesBlock {
    border: 1px solid #F2F2F2;
    padding: 35px;
}

.notesBlock p {
    color: #797979;
}

.noteHading {
    text-align: center;
    padding-bottom: 25px;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 45px;
    position: relative;
}

.noteHading::before {
    content: "";
    border-bottom: 2px solid #6FBE47;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0px;
    display: block;
    width: 119px;
    margin-left: auto;
    margin-right: auto;
}

.notesBlock .form-control {
    background-color: #eaeaea;
    border: 0;
    height: 250px;
    padding: 20px;
}

.notesBlock .greenBtn {
    text-align: right;
    margin-right: 0;
    width: 120px;
    margin-left: auto;
}

.accordion_block .btn-link {
    color: #333;
    font-size: 20px;
    font-weight: 600;
}

.accordion_block .btn-link:hover {
    text-decoration: none;
}

.accordion_block .card-header {
    border-bottom: 0px;
}

.accordion_block .card {
    margin-top: 8px;
}

.accordion_block table.table>tbody>tr>td:nth-child(2) {
    font-weight: 300;
}

.add-sign {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #6FBE47;
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 26px;
    color: #6FBE47;
    position: absolute;
    top: 20px;
    right: 60px;
}


/*20-11-2019*/

.lighgray {
    background-color: #F2F2F2;
    padding: 10px 0 50px 0;
}

.container1.checkbox_1 .checkmark {
    top: 7px;
    right: auto;
}

.container1.checkbox_1 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #6f6f6f;
    margin-left: 35px
}

.checkoptionBox.checkbox {
    justify-content: left;
}

.checkoptionBox.checkbox>li {
    padding-right: 25px;
    flex: 0 0 25%;
}

.search_Opportunity table.table>tbody>tr>td:first-child,
.search_Opportunity table.table>thead>tr>th:first-child {
    text-align: left;
}

.search_Opportunity table.table>tbody>tr td svg.CopyIcon {
    color: #333;
}

.search_Opportunity table.table>tbody>tr:hover td svg.CopyIcon {
    fill: #6FBE47;
}

.Opportunity_details_box {
    border: 1px solid #f4f4f4;
    padding: 40px;
}

ul.icon_display {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: right;
}

ul.icon_display>li {
    padding: 10px 12px;
}

ul.icon_display>li>a.active>svg,
ul.icon_display>li>a:hover>svg {
    fill: #6FBE47;
}

ul.managers_detail {
    margin: 0 0px 0 20%;
    padding: 0;
    list-style: none;
    position: relative;
}

ul.managers_detail::before {
    content: "";
    border-left: 1px solid #eaeaea;
    height: 100%;
    position: absolute;
    left: -43px;
}

ul.managers_detail>li {
    display: flex;
    position: relative;
}

ul.managers_detail>li>p>span {
    display: block;
}

ul.managers_detail>li>p {
    padding-left: 40px;
}

ul.managers_detail>li>svg {
    top: 8px;
    fill: #6FBE47;
    position: absolute;
}

ul.managers_detail>li>p>span:nth-child(2) {
    color: #959595
}

ul.managers_detail>li>p>a {
    color: #333;
    font-weight: 600;
    text-decoration: underline;
}

.digital_mark_had {
    margin-bottom: 15px;
}

.digital_mark_had h2 {
    color: #6FBE47;
    font-weight: 800;
}

.digital_mark_had h3 {
    color: #333;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 16px;
}

.digital_mark_had h4 {
    color: #959595;
    font-weight: 300;
    font-size: 16px;
}

h3.headingblack {
    color: #333;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 25px;
}

.digital_mark p {
    color: #959595;
}

ul.skill_box {
    margin: 25px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

ul.skill_box>li {
    padding-right: 8px;
    padding-bottom: 2px;
}

ul.skill_box>li>a {
    border: 1px solid #333;
    padding: 2px 16px;
    color: #333;
    border-radius: 8px;
}

ul.listing_block {
    color: #959595;
    margin: 0 0 25px 0;
    padding: 0 0px 0 18px;
}

ul.listing_block>li {
    padding-bottom: 5px;
}

.height_200 {
    height: 200px !important;
}

.list_form svg {
    fill: #6FBE47;
    color: #6FBE47;
    display: inline-block;
    margin-right: 15px;
}

.iconBlock {
    position: absolute;
    right: 40px;
    top: 45px;
}

.iconBlock .add {
    font-size: 25px;
    line-height: 18px;
    color: #b7b7b7;
}

.iconBlock span {
    display: block;
}

.iconBlock .minus {
    font-size: 45px;
    line-height: 18px;
    color: #b7b7b7;
}

.addBtn {
    width: 25px;
    height: 25px;
    border: 1px solid #6FBE47;
    position: absolute;
    border-radius: 50%;
    font-size: 31px;
    color: #6FBE47;
    text-align: center;
    line-height: 20px;
    right: 36px;
    top: 50px;
}
.contract_table,.add_contact_container{
    position: relative;
}
.contract_table .loader,
.add_contact .loader,
.add_contact_container .loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
    font-size: 2em;
    color:6FBE47;
}
.timesheetDetail{
    cursor: pointer;
}
.add_contact{
    position: relative;
}
.status.success{
    padding: 15px;
    background: 6FBE47;
    color: #fff;
    margin-top: 20px;
    font-size: 16px;
}
.status.error{
    padding: 15px;
    background:rgb(226, 72, 57);
    color: #fff;
    margin-top: 20px;
    font-size: 16px;
}
.clientDetailBtn{
    color: 6FBE47;
}
.clientDetailBtn:hover{
    color: #218838;
}
.client_contacts a{
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    text-align: center;
}
.client_contacts a img{
    display: block;
}