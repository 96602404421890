.card-picture-box {
    position: absolute;
    top: 0;
    left: 137px;
    z-index: 1000;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;

    @media screen and (min-width: 900px) {
        left: 140px;
    }

    @media screen and (min-width: 950px) {
        left: 145px;
    }

    @media screen and (min-width: 1000px) {
        left: 147px;
    }

    @media screen and (min-width: 1200px) {
        left: 150px;
    }
}



.card-picture {
    object-fit: cover;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.card-formatting {
    width: 21rem;
    height: 90%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
    
}

.member-name {
    color: #6FBE47;
    font-weight: bold;
    font-family: Lato 900;
}

.member-role {
    font-weight: bold; 
    font-family: Lato 900;
}

.member-edu-and-avail {
    color: #6FBE47; 
    display: inline;
}

.member-registered {
    padding-left: 45px;
}

.view-profile-link {
    font-weight: bold;
    color: black;

}
.view-profile-link:hover {
    text-decoration: none;
    color: #6FBE47;
}

.add-to-list {
    padding-left: 5px;
    font-weight: bold;
}