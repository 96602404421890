.top-nav-name {
    font-family: Lato 900;
    font-weight: bold;
    font-size: 30px;
    @media screen and (max-width: 500px) {
        display: none;
      }
}

.teckpert-logo {
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    @media screen and (max-width: 500px) {
        display: none;
      }
}

.profile-picture {
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.profile-box {

    border-radius: 50%;
    width: 4rem;
    height: 4rem;
}

.profile-name-dropdown {
    font-family: Lato 700; 
    padding-right: 1.5rem; 
    padding-left: 1rem;
}

.profile-name {
    color: gray;
    font-weight: bold;
}

.bell-icon {
    color: gray;
    width: 1.25rem;
    height: 2.75rem;
}

.message-icon {
    color: gray;
    width: 1.25rem; 
    height: 2.75rem;
}
.sendToClient{
    color: #fff;
}
.sendPayroll{
    color: #fff;
    margin-right: 20px;
}
.sendPayrollBtn{
    margin-right: 20px;
}