$--color-grey-light-2: #f4f2f2;
$--line: 2px solid $--color-grey-light-2;

// ul.infoContainer {
//   padding-top: 1em !important;

// }
// div.Categories {
//   padding-top: 0 !important;
// }
.background {
  background-color: #6FBE47;
}
.contract_table .loader,
.member_details_block .loader,
.add_contract_container .loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  font-size: 2em;
  color: 6FBE47;
}
.add_contract_container{
  position: relative;
}
.member_details_block{
  margin-bottom: 40px;
}
.member_details_block .loader {
  position: fixed;
  left: 0;
}
.main-head {
  max-width: 70rem;
  background-color: white;
  display: flex;
  margin: 0rem auto;

  .gridItem1 {
    display: grid;
    grid-template-columns: 200px;
    grid-template-rows: 40px;
    background-color: #6FBE47;
  }
  .Header {
    .HeaderUl {
      display: flex;
      list-style: none;
      padding-left: 10rem;
      align-items: center;
    }
    li {
      display: flex;
      // flex-direction: row;
      justify-content: flex-start;
    }
    span {
      padding-right: 3em;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      // flex-direction: row;
    }
    span:hover {
      border-block-end: 2px solid #6FBE47;
      animation: dash 1s linear;
    }

    .rightIcons {
      display: flex;
      padding-left: 1em;
      padding-top: 0.5em;
      li {
        padding-left: 3em;
      }
    }
  }
}
.memberProfilePicture {
  width: 200px;
  height: 200px;
  color: white;
  grid-column: span 4/-1;
  font-size: 19em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .memberProfilePictureImage{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }
}
.infoContainer {
  padding-left: 2em;
  // padding-top: .6em;
  padding-top: 1em;
  li {
    align-items: center;
    // margin-bottom: 11px;
    margin-bottom: 16px;
    p {
      margin-bottom: 0px;
    }
    a {
      display: inline-block;
      margin-bottom: 6px;
      color: inherit;
      .icon2 {
        color: #6FBE47;
        display: inline-block;
        margin-right: 10px;
      }
      span {
        border-bottom: 1px solid;
      }
    }
    a:hover {
      border-bottom: none;
      text-decoration: none;
    }
  }
  li.isLink {
    margin-bottom: 0px;
  }
}
.Main-Card {
  background-color: white;
  max-width: 70rem;
  height: 25em;
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 200px 600px 320px;
  grid-template-rows: 400px;
}

.icon1 {
  color: white;
  background: #dddfe1;
  grid-column: span 4/-1;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.column {
  &--1 {
    -webkit-box-shadow: 0 6rem 6rem rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
    box-shadow: -1rem -0.5rem 2.5rem -1rem rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
    background-color: #6FBE47;
    align-items: center;
  }

  &--2 {
    header {
      display: flex;
      // max-height: 75px;
      max-height: 65px;
    }
  }

  &--3 {
    box-shadow: 2rem -0.5rem 1rem -1.6rem rgba(85, 5, 5, 0.3);
    border-left: $--line;
    // max-height: 75px;
    // height: 72px;
  }
}

.Categories {
  display: column;
  padding-left: 8em;
  // padding-top: 5em;
  padding-top: 1.5em;
  .Name {
    font-weight: 1000;
    font-size: 2em;
    color: #6FBE47;
  }
  .Role {
    font-size: 1.5em;
    font-weight: 1000;
  }
  .DateRegiestered {
    padding-top: 0.5em;
    font-style: italic;
    color: grey;
  }
}
.infoContainer a {
  cursor: pointer;
}
.Education {
  &__list {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
      &__year {
        display: inline-block;
        width: 100px;
        min-width: 100px;
        text-align: right;
        padding-right: 10px;
      }
      &__school {
        display: inline-block;
        padding-left: 10px;
        p {
          color: #999;
        }
      }
      &__actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-size: 14px;
        color: #999;
        margin-top: 4px;
        a {
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
.Specializations {
  &__list {
    padding: 0px 20px;
    color: #999;
    h5 {
      color: #333;
      font-weight: bold;
      margin-bottom: 16px;
    }
    &__item {
      display: inline-block;
      padding: 0px 4px;
      &__saperator {
        padding: 0px 2px;
      }
    }
  }
}
.profileTabs.main-header .nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 200px;
}
.profileTabs.main-header .nav-item {
  font-size: 1.5em !important;
  padding-left: 0;
  font-weight: bold;
}

.preferenceInfo {
  display: grid;
  grid-template-columns: 450px 450px;
  grid-template-rows: 13rem;
  align-items: center;
  align-content: center;
  margin: 0rem auto;
  width: 50rem;
  font-size: 1em;
  li {
    display: flex;
    list-style: none;
    .Icon3 {
      top: 4px;
      position: relative;
      color: #6FBE47;
    }
  }
  .pInfo {
    padding-left: 1em;
    text-transform: capitalize;
  }
  span {
    display: flex;
    flex-direction: column;
    color: gray;
  }
}

.main-header .nav-link.active .categories {
  border-block-end: 4px solid #6FBE47;
  animation: dash 1s linear;
}
.worker_contract .buttonBlock {
  display: none;
}
.worker_contract .default_margin {
  display: none;
}

.requests {
  &__container {
    margin-bottom: 20px;
    h4 {
      font-size: 20px;
    }
    &__items {
      margin-top: 20px;
      &__item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px;
        span {
          display: inline-block;
          margin-right: 20px;
          padding: 3px 10px;
          font-size: 14px;
          border: 1px solid #999;
          border-radius: 4px;
        }
      }
    }
  }
}
.ProfileHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
  &__ctas {
    width: 66%;
    text-align: right;
    .btn {
      margin-left: 16px;
    }
  }
  &__actions {
    &__list {
      display: block;
      list-style: none;
      margin-right: 20px;
      li {
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        color: #C9CACE;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: #6FBE47;
        }
      }
    }
  }
}

.formBody {
  padding: 8px 20px;
  border: 1px solid #ced4da;
}
.input {
  height: 50px;
  border-radius: 0;
  padding: 8px 20px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}