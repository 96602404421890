#wrapper {
  min-width: 2px;
}

.App {
  text-align: center;
}

.form-control:focus {
  border-color: #6FBE47;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 

@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-fileupload-group {
  position: relative;
}
.form-fileupload-group .fileLoader {
  position: absolute;
  top: 34px;
  left: 34px;
}