
.infoContainer {
    padding-top: 2em;
    list-style: none;
    padding-left: 2em;
    // flex-direction: row;
    .icon2{
      color:#6FBE47; 
    }
    
    .info { 
      padding-left: 1em;
      display: flex;
      flex-direction: column;
      list-style: none;
      font-size: 0.9em;

    }
    
    li {
      align-items: flex-start;
      
      display: flex;
      padding-left: 0em;
      list-style: none;
      
      }

  }

  .SocialMediaContainer{
    list-style: none;
    padding-left: 6em;
    display: flex;
    flex-direction: column;

    // align-items: center;
    li {
      display: flex; 

      .icon2 {
      color:#6FBE47;
       
      }
      span {
        display: flex;
        padding-left: 1em; 

      }

    }
  }