h3 {
    font-weight: bold;
    padding: 2% 3%;
}

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    padding: auto 5%;
    text-align: center;
}

.value {
    color: rgb(111, 190, 71);
}

.people {
    width: 30%;
    flex-flow: column;
}

.on-contract {
    width: 30%;
    flex-flow: column;
}

.vetted {
    width: 30%;
    flex-flow: column;
}

.main-container h3, h4 {
    padding: 0 3% 1%;
}

h6 {
    padding-top: 2%;
}