$--color-grey-light-2: #f4f2f2;
$--line: 2px solid $--color-grey-light-2;

.background {
  background-color: #6FBE47;
}

.main-head {
  max-width: 70rem;
  background-color: white;
  display: flex;
  margin: 0rem auto;

  .gridItem1 {
    display: grid;
    grid-template-columns: 200px;
    grid-template-rows: 40px;
    background-color: #6FBE47;

  }
  .Header {
    
    .HeaderUl {
      display: flex;
      list-style: none;
      padding-left: 10rem;
      align-items: center;

    }
    li {
      display: flex;
      // flex-direction: row;
      justify-content: flex-start;
    }
    span {
      padding-right: 3em;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      // flex-direction: row;
    }
    span:hover {
      border-block-end:2px solid #6FBE47;
      animation: dash 1s linear;
    }

    .rightIcons {
      display: flex;
      padding-left: 1em;
      padding-top: 0.5em;
      li {
        padding-left: 3em;
      }
    }
  }
}

.Main-Card {
  background-color: white;
  max-width: 70rem;
  height: 33em;
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 200px 520px 400px;
  grid-template-rows: 528px;
}

.icon1 {
  color: white;
  background: #dddfe1;
  grid-row: 3/6;
  grid-column: span 6/-1;
  width: 115%;

  font-size: 19em;
}

.column {

  &--1 {
    -webkit-box-shadow: 0 6rem 6rem rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
    box-shadow: -1rem -0.5rem 2.5rem -1rem rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
    background-color: #6FBE47;
    align-items: center;
  }

  &--2 {
    header {
      display: flex;
      max-height: 65px;
    }
  }
  
  &--3 {
    box-shadow: 2rem -0.5rem 1rem -1.6rem rgba(85, 5, 5, 0.3);
    border-left: $--line;
  }
}

.Categories {
  display: column;

  padding-left: 8em;
  padding-top: 1.5em;
  .Name {
    font-weight: 1000;
    font-size: 2em;
    color: #6FBE47;
  }
  .Role {
    font-size: 1.5em;
    font-weight: 1000;
  }
  .DateRegiestered {
    padding-top: 0.5em;
    font-style: italic;
    color: grey;
  }
}
