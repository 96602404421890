#custom-drop {
    background-color: white !important;
    border-color: lightgray !important;
    width: 100%;
    text-align: left !important;
    color: rgb(74, 74, 75) !important;
    height: 39px;
}

#custom-drop::after{
    float: right !important;
    margin-top: 10px !important;
}


button.btn-close {
    background-color: white !important;
    border: none !important;
    padding: 0 !important;
}

button.btn-close::before {
    content: "x" !important;
    font-weight: bold;
    color: gray !important;
    font-size: 20px !important;
    background-color: white !important;
    border: none !important;
}

#ul-custom-filter {
    width: 10%;
}