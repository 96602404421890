.btn-primary {
    background-color: #6FBE47 !important;
    border-color: #6FBE47 !important;
}
.file-upload {
    background-color: #6FBE47 !important;
    border-color: #6FBE47 !important;
    color: #6FBE47 !important;
}
.modal-backdrop.fade {
    opacity: 0.1;
    filter: alpha(opacity=0);
}
.modal-backdrop.fade.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.time_sheet_table{
    position: relative;
}
.time_sheet_table .loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
    font-size: 2em;
    color:6FBE47;
}