.profile {
    p {
      width: 70%;
      margin-left: 8em;
      margin-top: 1em;
      text-align: left;
    }
    span {
      display: flex;
    }
  }

  .main-header {
    margin: 0rem auto;
    max-width: 70rem;

    // padding-bottom: 6em;
    border:2px solid #f4f2f2;
    padding-top: 1em;
    // margin-top: 1em;
.nav-link {
    // style:none;
    // color: black; 
    background: white;

    
}
 .nav-item {
    padding-left: 3em;
    align-content: center;
    font-size: 2.5em;
    color: black;
    // padding-bottom: 1em;
    
 }
 .categories{
    color: black; 
    
    
    }
 .categories:hover{
    border-block-end:4px solid #6FBE47;
    animation: dash 1s linear;
 }
 .nav {
     color: black
 }
}

