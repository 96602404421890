$bp-largest: 75em; //1200px
$bp-med: 56.25em; //900px

.Container {
  max-width: 240px;
  margin-top: 10rem;
  //  font-size: 1em;
  //  max-width: 0px;
  //  margin-top: 0%;
  //  padding-top: 0%
  // background-color: #333333;
}

// background-color: black;

// ////////////////////////////
//SIDE NAV
.side-nav {
  font-size: 2em;
  list-style: none;
  color: white;
  //  width: 200px;
  height: 100%;
  position: fixed;
  //  z-index: 100;
  top: 1;
  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &__item.active {
    background-color: #6FBE47;
  }

  &__item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 3px;
    background-color: #6FBE47;
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  }

  &__item:hover::before {
    transform: scaleY(1);
    width: 100%;
  }

  &__link:link {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    // z-index: 10;
  }

  &__icon {
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    margin-left: 1.5em;
    width: 1rem;
    height: 2.75rem;
    color: white;
    fill: #000;
    align-items: center;
  }

  &__category {
    padding-left: 1em;
    color: white;
    font-size: 1em;
  }
}

.navButton {
  position: fixed;
  top: 0%;
  max-width: 200px;
  display: flex;
  align-content: flex-start;
  z-index: 100;
}

.oneIcon {

  position: relative;
 margin-top: 24px;
  color: white;
  margin-left: -17px;
  margin-bottom: 1em;
  font-size: 2em;
  width: 30px !important;
  z-index: 1000;
  transition: 300ms ease-in-out;
  padding: 10px !important;
}

.secondIcon {
  position: relative;
  background-color: #6FBE47;
  color: white;
  margin-left: -17px;
  margin-top: 24px;
  font-size: 2em;
  width: 30px !important;
  z-index: 1000;
  transition: 300ms ease-in-out;
}

figure {
  cursor: pointer;
}

.sidebar {
  padding-top: 0em;
  display: flex;
  background-color: #333333;
  width: 55px;
  height: 100%;
  background-size: 0 100%;
  background-position: 0 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  // transform: translateX(150px);
  transition: 300ms ease-in-out;
  animation: slide-open 300ms forwards;
  //  z-index: 2000;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;

  &--expanded {
    background-size: 50%;
    background-position: 0 0;
    transform: translateX(0px);
    width: 240px;
    z-index: 2;
  }

  &:hover {
    background-position: 0 0;
  }

  @media only screen and (max-width: $bp-largest) {
    margin: 0;
    // background: red;
  }
}

.sidenavlogo {
  display: flex;
  position: fixed;
  top: 75px;
  left: 25px;

  animation: slide-open 300ms fadeIn;
  transition: 300ms ease-in-out;

  z-index: 1000;
}

/////////////////////////////////
//Media Queries
/////////////////////////////////

@media screen and (max-width: 800px) {
  .side-nav {
    text-align: center;
    float: none;
  }
}
