@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');



body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


th {
  font-weight: normal;
}

.topRow {

 border: 0 !important;
}

.table>tbody>tr.topRow>td,
.table>thead>.topRow> {
  border-top: none;
  border-bottom: none;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
