.top-nav-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 52px;
    margin-left: 40px;
    @media screen and (max-width: 500px) {
        display: none;
      }
}

.teckpert-logo {
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    @media screen and (max-width: 500px) {
        display: none;
      }
}

.profile-picture {
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: -15px;
}

.profile-box {

    border-radius: 50%;
    width: 4rem;
    height: 4rem;
}

.profile-name-dropdown {
    font-family: Lato 700; 
    padding-right: 1.5rem; 
    padding-left: 1rem;
}

.profile-name {
    color: gray;
    font-weight: bold;
}

.bell-icon {
    color: gray;
    width: 1.25rem;
    height: 2.75rem;
}

.message-icon {
    color: gray;
    width: 1.25rem; 
    height: 2.75rem;
}
.sendToClient{
    color: #fff;
}
.sendPayroll{
    color: #fff;
    margin-right: 20px;
}
.sendPayrollBtn{
    margin-right: 0px;
}
.userMenuToggle button {
    background: transparent;
    color: #333;
    border: none;
    background-color: transparent !important;
}
.userMenuToggle button:active,
.userMenuToggle button:hover,
.userMenuToggle :focus{
    background: transparent;
    color: #333 !important;
    border: none;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
.top_nav ul.navbar-nav{
}
