$bgcolor: lightblue;
$textcolor: gray;
$fontsize: 16px;
$paddind_0:0px;
$inlinebox:flex;
.table {
    border-collapse: separate;
    border-spacing: 0 3px;
}

ul.search_boxnew,
ul.calendarnew {
    margin: 35px 0 30px 0;
    padding: $paddind_0;
    display: $inlinebox;
    list-style: none;
    border: 1px solid #ccc;
}


ul.search_boxnew>li.search-box {
    flex: 0 0 100% !important;
}
ul.search_boxnew {
    display: inline-flex;
}
ul.search_filters {
    margin-top: 36px !important;
}
@media (max-width: 1448px) {
    ul.search_boxnew {
        margin-bottom: -15px;
        width: 100%;
    }
    ul.search_filters {
        margin-top: 12px !important;
        width: 100% !important;
        margin-bottom: 20px;
    }
}
@media (min-width: 1448px) {
    ul.search_boxnew {
        width: 30%;
    }
    ul.search_filters {
        width: 69%;
    }
}

ul.search_boxnew.single-search {
    width: 100%;
}

ul.search_boxnew>li.search-box {
    flex: 0 0 33.9%;
    height: 50px;
}
ul.search_boxnew>li.contractFilter{
    flex: 0 0 26.9%;
}

ul.search_boxnew>li.dropdown {
    flex: 0 0 20%;
}

ul.search_boxnew>li {
    border-right: 1px solid lightgray;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.search_boxnew>li a {
    color: $textcolor;
    font-size: $fontsize;
}

ul.search_boxnew>li:last-child {
    border: 0;
}

ul.search_boxnew>li.search-box .form-control {
    border: 0px !important;
    height: 50px;
    padding: 0px 25px;
}

ul.calendarnew {
    height: 65px;
    padding-left: 25px;
    padding-right: 25px;
}

.date-text {
    height: 65px;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $textcolor
}

.calendarnew>li {
    display: flex;
}

.calendarnew>li .input-group.date .form-control {
    height: 62px;
    border: 0;
    color: $textcolor
}

.calendarnew>li .input-group.date .form-control:focus {
    box-shadow: none;
}

table.table>thead>tr {
    background-color: #F2F2F2;
}

table.table>thead>tr>th,
table.table>tbody>tr>td {
    vertical-align: middle;
    font-size: 14px;
}

table.table>tbody>tr>td {
    padding: 22px 10px;
}

table.table>thead>tr>th:first-child {
    padding-left: 25px;
    line-height: 23px;
    text-align: center;
}

table.table>thead>tr>th:first-child {
    border-left: 1px solid #dee2e6;
}

table.table>thead>tr>th:last-child {
    border-right: 1px solid #dee2e6;
}

table.table>thead>tr>th:first-child>span {
    font-size: 11px;
}

.table th,
.table td {
    border-bottom: 1px solid #dee2e6;
}

table.table>tbody>tr>td:first-child {
    padding-left: 25px;
    border-left: 1px solid #dee2e6;
}

table.table>tbody>tr>td:last-child {
    border-right: 1px solid #dee2e6;
}

table.table>tbody>tr>td {
    vertical-align: middle;
}

table.table>tbody>tr>td:nth-child(2) {
    font-weight: 600;
}

hr.line {
    margin-top: 15px;
    padding-bottom: 20px;
}

.search_boxnew .btn {
    padding-right: 25px;
}

table.table>thead>tr>th {
    height: 62px;
}


/*check box */

.container1 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    right: 0;
    margin: auto;
}

.container1:hover input~.checkmark {
    background-color: #ccc;
}

.container1 input:checked~.checkmark {
    background-color: #6FBE47;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container1 input:checked~.checkmark:after {
    display: block;
}

.container1 .checkmark:after {
    left: 4px;
    top: -1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

table.table>tbody>tr>td:first-child {
    text-align: center;
}


/* Timesheets Period model style*/

.modelBox {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 25px;
}

ul.profildetail {
    list-style: none;
    display: flex;
    align-items: center;
}

ul.profildetail li.username {
    padding-left: 35px;
}

ul.profildetail li.username h3 {
    color: #6FBE47;
    font-weight: 600;
    margin-bottom: 0;
}

.userprofil {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #F2F2F2;
    border: 1px solid #ccc;
}

.username h3 {
    font-size: 38px;
}
.detailedClientTimeSheet .username{
    width: 60%;
}
ul.profildetail li.username h4 {
    font-weight: 600;
    font-size: 25px;
}

ul.profildetail li.username h5 {
    font-weight: 300;
    opacity: 0.5;
}

.greenBtn {
    background-color: #6FBE47;
    color: #fff !important;
    padding: 14px 40px;
    font-weight: 300;
    font-size: 17px;
    display: block;
    cursor: pointer;
}

.greensmalltext {
    color: #6FBE47 !important;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}


/*Clients for Approval */

.from-for .form-label {
    padding-left: 20px;
    padding-right: 20px;
}

.from-for .form-control {
    height: 50px;
    border-radius: 0;
    padding: 8px 20px;
}
.from-for textarea.form-control{
    height: auto;
}

ul.buttonBlock {
    margin: 15px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

ul.buttonBlock>li {
    padding-right: 20px;
}

ul.buttonBlock .greenBtn {
    width: 150px;
    text-align: center;
}

.width-80 {
    width: 140px;
    text-align: center;
}

.pyroll {
    justify-content: right;
}

.pyroll .form-control {
    height: 55px;
    border-radius: 0;
}

ul.pyroll {
    list-style: none;
    display: flex;
    align-items: center;
}


/* Report issues with timesheets*/

.status_block {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    color: #959595;
}

.status_block>li:nth-child(1),
.status_block>li:nth-child(3),
.status_block>li:nth-child(5) {
    text-align: right;
    padding-right: 25px;
    ;
}

.status_block>li,
.status_block>li:last-child {
    flex: 0 0 50% !important;
}

.status_block>li:last-child {
    justify-content: left !important;
}

.status_block>li>span {
    display: block;
}

.boderbox {
    border: 1px solid #F2F2F2;
    padding: 35px;
}

.h20 {
    height: 240px !important;
}
.sortcolumn{
    color: #000;
}
.detailed_time_sheet_modal ul.profildetail li.username{
    display: block;
}
.time_sheet_table,.send_to_client_container{
    position: relative;
}
.time_sheet_table .loader,
.send_to_client_container .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
    font-size: 2em;
    color:6FBE47;
}
.timesheetDetail{
    cursor: pointer;
}
.react-bootstrap-daterangepicker-container {
    height: 52px;
    border: none;
    padding: 0 24px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .Search__iconCloseDatePicker {
        color: #000000;
        font-size: 10px;
        width: 25px !important;
        height: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .rangeButton {
        display: flex;
        height: 100%;
        background: transparent;
        color: #333;
        justify-content: center;
        align-items: center;
        border: none;
        &:hover,&:focus,&:active{
            color: #333;
            background-color: transparent;
            box-shadow: none;
            outline:none;
        }
    }
}
.selectAll,.clearAll{
    cursor: pointer;
}
.filterToggle {
    display: flex;
    height: 100%;
}
.filterToggle button {
    background: transparent;
    color: #333;
    border: none;
    background-color: transparent !important;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}
.filterToggle button:active,
.filterToggle button:hover,
.filterToggle :focus{
    background: transparent;
    color: #333 !important;
    border: none;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
.sendPayroll.greyed,.sendToClient.greyed{
    opacity: 0.5;
    cursor: no-drop;
}

.quickDL {
    color: #0588BC;
    text-decoration: underline;
    cursor: pointer;
    letter-spacing: 1px;
    font: 16px Lato;
    font-weight: 400;
}