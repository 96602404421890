    .Search {
    margin: 0rem auto;
    display: flex;
    height: 52px;
    margin-top: 2em;
    max-width: 0em;

  &__SearchBar {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    padding: 1em 5em  1em  1em ;
    // padding: 1em;
    border: 1px solid lightgray;
    border-right-style: none;
  }

  &__SearchBar:focus {
    outline:none;
  }

  &__iconSearch {
    font-size: 20px;
    padding-right: 10px;
    padding-top: 10px;
    border: 1px solid lightgray;
    border-left-style: none;
  }
  &__iconClose {
    font-size: 20px;
    padding: 1em 1em 1em 1em;
    border: 1px solid lightgray;
    border-left-style: none;
    color: #ff0000;
    cursor: pointer;
  }
}

.togStyle {
    padding: 1em 1em 0.5em 1em;
    border: 1px solid lightgray;
    position: relative;
    text-decoration: none !important;
}
.nav-item {
  display: flex;

  // margin-left: .5em;
}
