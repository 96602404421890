.Search {
  max-width: none !important;
  margin: 20px 0 20px 0 !important;
  &__SearchBar {
    width: 100%;
  }
}
.Messaging {
  &__Right {
    background-color: #f7f7f7;
    min-height: calc(100vh - 122px);
    max-height: calc(100vh - 122px);
    height: calc(100vh - 122px);
  }
  &__Left {
  }
  &__List {
    overflow-y: auto;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
    height: calc(100vh - 300px);
    &__Item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
      &__Picture {
        width: 60px;
        &__Image {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          background-color: #ddd;
          img{
            width: 40px;
            height: 40px;
          }
        }
      }
      &__Content {
        width: 100%;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &__Name {
          color: #333;
          display: block;
          font-weight: bold;
        }
        &__Msg {
          color: #999;
          display: block;
          font-size: 13px;
        }
      }
      &__Time {
        width: 100px;
        font-size: 12px;
        color: #999;
        padding-top: 10px;
        text-align: right;
      }
      &:hover {
        background-color: #fff;
        cursor: pointer;
        &__Content {
          &__Name {
            color: #fff;
          }
          &__Msg {
            color: #fff;
          }
        }
      }
    }
    &__Item.active {
      background-color: #fff;
      cursor: pointer;
      &__Content {
        &__Name {
          color: #ffffff !important;
        }
        &__Msg {
          color: #ffffff !important;
        }
      }
    }
  }
}
.Chat_section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.Chat__List {
  overflow-y: auto;
  .divider {
    text-align: center;
    position: relative;
  }
  &__Item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
    &__Picture {
      width: 60px;
      &__Image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #ddd;
      }
    }
    &__Content {
      width: 100%;
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__Name {
        color: #333;
        display: block;
        font-weight: bold;
        small {
          font-size: 12px;
          color: #999;
        }
      }
      &__Msg {
        color: #999;
        display: block;
        font-size: 13px;
      }
    }
    &__Time {
      width: 100px;
      font-size: 12px;
      color: #999;
      padding-top: 10px;
      text-align: right;
    }
  }
}
.Chat_section .form-group {
  margin-top: 20px;
}
.loader {
  position: absolute;
  width: calc(100% - 240px);
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  font-size: 2em;
  color: 6FBE47;
}
.Message_New {
  margin-top: 20px;
  .react-tags {
    background-color: #fff;
  }
}